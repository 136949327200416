import React from "react";
import classNames from "classnames";

type Props = {
  /**
   * The collection of radio buttons in the group.
   */
  children?: JSX.Element[];
  className?: string;
  /**
   * The label, title, or header for the radio group.
   */
  label?: string;
  labelClassName?: string;
  /**
   * Provide a `labelOrder="before"` prop to display labels before radio buttons.
   *
   * Labels are displayed after radio buttons by default.
   */
  labelOrder?: "before" | "after";
  /**
   * The name of the radio group. Will be propagated to all radio buttons in the group.
   */
  name: string;
} & React.HTMLAttributes<HTMLFieldSetElement>;

const RadioGroup: React.FC<Props> = ({
  children,
  className,
  label,
  labelClassName,
  labelOrder = "after",
  name,
  ...others
}) => (
  <fieldset className={classNames(className)} {...others}>
    {label && (
      <legend className={labelClassName ?? "font-bold mb-md"}>{label}</legend>
    )}
    {children?.map((c, i) => {
      const Element = c.type;
      return (
        <Element key={i} name={name} labelOrder={labelOrder} {...c.props} />
      );
    })}
  </fieldset>
);

export default RadioGroup;
