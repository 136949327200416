import classNames from "classnames";

import { MenuType } from "utils/menuTypeUtils";

import MenuTypeButton from "../MenuTypeButton";
import MenuTypeDisplay from "../MenuTypeDisplay";

const MenuTypeSelection = ({
  className,
  isDualMenu,
  onMenuTypeChange,
  showSelectInput,
}: {
  className?: string;
  isDualMenu: boolean;
  onMenuTypeChange?: (value: MenuType.Med | MenuType.Rec) => void;
  showSelectInput: boolean;
}) =>
  isDualMenu ? (
    <div className={classNames("flex flex-row-reverse", className)}>
      {showSelectInput ? (
        <MenuTypeButton onMenuTypeChange={onMenuTypeChange} />
      ) : (
        <MenuTypeDisplay />
      )}
    </div>
  ) : (
    <></>
  );

export default MenuTypeSelection;
